import { commify, formatUnits } from 'ethers/lib/utils'
import { showToast } from '@utils/toast'
import {
  GOKOO_TOKEN,
  TOAST_TYPE,
  TRANSACTION_STATE
} from '@common/constants/common'
import { BigNumber, ethers } from 'ethers'
import { isNil } from 'lodash'
import { USDC_CONTRACT_ADDRESS_ON_ETHEREUM } from '@common/contracts'
import numeral from 'numeral'
import { useQuery } from '@tanstack/react-query'
import {
  formatUnits as formatUnitsV2,
  erc20Abi,
  isAddress,
  getAddress
} from 'viem'
import { publicClient } from './wagmi-config'

export const formatReadable = (value, unitName = 18, decimals = 4) => {
  const formatted = formatUnitsIfExist(value, unitName)

  if (isNil(formatted)) return '0.0000'

  const parts = formatted.split('.')
  const intPart = commify(parts[0])
  const decimalPart = parts[1]

  if (isNil(decimalPart)) return `${intPart}.${''.padEnd(decimals, '0')}`

  if (decimalPart.length < decimals) {
    return `${intPart}.${decimalPart.padEnd(decimals, '0')}`
  }

  const res = `${intPart}.${decimalPart.substring(0, decimals)}`

  if (res.endsWith('.')) {
    return res.substring(0, res.length - 1)
  }

  return res
}

export const formatUnitsIfExist = (value, unitName) =>
  value ? formatUnits(value, unitName) : undefined

export const onTransactionEnd = (state, error, onSuccess) => {
  if (state === TRANSACTION_STATE.ERROR) {
    showToast('Contract transaction failed', TOAST_TYPE.ERROR)
    return
  }

  if (state === TRANSACTION_STATE.SUCCESS) {
    showToast('Success', TOAST_TYPE.SUCCESS)
    onSuccess && onSuccess()
  }
}

export const formatCurrency = (price) => {
  const formattedPrice = formatReadable(
    BigNumber.from(price || 0),
    GOKOO_TOKEN.decimals,
    2
  )

  // Remove any trailing decimal points
  const cleanPrice = formattedPrice.replace(/\.$/, '')

  return cleanPrice
}

export const formatCurrencyV2 = (price) => {
  return `${formatReadable(
    BigNumber.from(price || 0),
    GOKOO_TOKEN.decimals,
    2
  )}`
}

export const formatGokooDisplay = (amount) => {
  return numeral(amount).format('0,0.00')
}

export const roundBigNumber = (number) => {
  const num = number.toNumber() / 1e6
  const roundedNum = Math.round((num + Number.EPSILON) * 100) / 100
  return BigNumber.from(roundedNum * 1e6)
}

export const compareAddresses = (address1, address2) => {
  if (!isAddress(address1) || !isAddress(address2)) {
    return false
  }

  const checksumAddress1 = getAddress(address1)
  const checksumAddress2 = getAddress(address2)

  return checksumAddress1 === checksumAddress2
}

export const waitForReceipt = async (txHash) => {
  const tx = await publicClient.waitForTransactionReceipt({
    hash: txHash
  })

  console.log(tx)

  return { isSuccess: tx.status === 'success', tx }
}

export const getUsdcReserveOnEthereum = async () => {
  const provider = new ethers.providers.JsonRpcProvider(
    'https://eth.llamarpc.com'
  )

  // USDC ABI
  const usdcAbi = ['function balanceOf(address owner) view returns (uint256)']

  const usdcContract = new ethers.Contract(
    USDC_CONTRACT_ADDRESS_ON_ETHEREUM,
    usdcAbi,
    provider
  )

  const address = process.env.REACT_APP_MULTI_SIG_ADDRESS
  const balance = await usdcContract.balanceOf(address)

  const formattedBalance = ethers.utils.formatUnits(balance, 6)

  return formattedBalance
}

export const useUsdcReservationOnEth = () => {
  const address = process.env.REACT_APP_MULTI_SIG_ADDRESS

  return useQuery({
    queryKey: ['usdc-reservation-on-eth'],
    queryFn: async () => {
      if (!address) {
        return 0
      }

      const balance = await publicClient.readContract({
        abi: erc20Abi,
        address: USDC_CONTRACT_ADDRESS_ON_ETHEREUM,
        functionName: 'balanceOf',
        args: [address]
      })

      const formattedBalance = formatUnitsV2(balance, 6)

      return formattedBalance
    }
  })
}
