import { React, useState, useEffect, useRef } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import CheckoutForm from './CheckoutForm'
import { loadStripe } from '@stripe/stripe-js'
import { Box, CircularProgress } from '@mui/material'
import ErrorText from '@components/form-components/ErrorText'

const StripePayment = (props) => {
  const { open, onClose } = props

  const [stripePromise, setStripePromise] = useState(null)
  const [clientSecret, setClientSecret] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  const configCalledRef = useRef()

  useEffect(() => {
    if (!open) {
      configCalledRef.current = false
      return
    }
    if (configCalledRef.current) return

    configCalledRef.current = true

    fetch(process.env.REACT_APP_STRIPE_LICENS_CONFIG_ENDPOINT).then(
      async (r) => {
        const { publishableKey } = await r.json()
        setStripePromise(loadStripe(publishableKey))
      }
    )
  }, [open])

  const calledRef = useRef()
  useEffect(() => {
    if (!open) {
      calledRef.current = null
      return
    }

    if (calledRef.current === props.amount) {
      return
    }

    setIsLoading(true)
    calledRef.current = props.amount
    fetch(process.env.REACT_APP_STRIPE_LICENS_INTENT_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ amount: props.amount })
    })
      .then(async (result) => {
        const { clientSecret } = await result.json()
        setClientSecret(clientSecret)
        setIsLoading(false)
      })
      .catch(() => {
        setClientSecret(null)
        setIsLoading(false)
      })
  }, [open, props.amount])

  if (!open) return null

  return (
    <Box height="100%">
      <Box
        id="payment-drawer"
        sx={{
          width: '100%',
          height: '100%'
        }}
      >
        {isLoading ? (
          <Box
            position="absolute"
            top="45%"
            left="45%"
            transform="translate(-50%, -50%)"
          >
            <CircularProgress />
          </Box>
        ) : clientSecret && stripePromise ? (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm
              onPaymentStart={props.onPaymentStart}
              onPaymentEnd={props.onPaymentEnd}
              onPaymentSucceeded={props.onPaymentSucceeded}
              onStripeIdReturned={props.onStripeIdReturned}
              onClose={onClose}
            />
          </Elements>
        ) : (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          >
            <ErrorText
              sx={{
                fontSize: '16px'
              }}
            >
              An unexpected error occured.
            </ErrorText>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default StripePayment
