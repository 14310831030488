import { CURRENT_USER_ACCESS_TOKEN } from '@common/constants/common'
import axios from 'axios'

let toastTimeout

export const getAxiosInstance = () => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_LICENS_CHECKOUT_ENDPOINT,
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  })

  // Request interceptor for adding the access token to headers
  axiosInstance.interceptors.request.use(
    (config) => {
      const accessToken = localStorage.getItem(CURRENT_USER_ACCESS_TOKEN)

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  // Response interceptor for handling token expiration or renewal if needed
  axiosInstance.interceptors.response.use(
    (response) => {
      // Handle successful response
      return response
    },
    (error) => {
      if ([401].includes(error.response.status)) {
        toastTimeout && clearTimeout(toastTimeout)
        toastTimeout = setTimeout(() => {
          const customEvent = new CustomEvent('TRIGGER_LOGIN', {
            detail: { message: 'Your session expired!' }
          })
          window.dispatchEvent(customEvent)
        })
        localStorage.removeItem(CURRENT_USER_ACCESS_TOKEN)
      }
      return Promise.reject(error)
    }
  )

  return axiosInstance
}

const axiosInstance = getAxiosInstance()

export default axiosInstance
