// noinspection JSUnresolvedFunction

import { useEffect, useState } from 'react'
import { ACCOUNT_CONTRACT } from '@common/contracts'

export const useRegistered = (address) => {
  const [registered, setRegistered] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchRegisterStatus = async () => {
      if (address) {
        const isRegistered = await ACCOUNT_CONTRACT.isRegistered(address)

        setRegistered(!!isRegistered)
      }

      setLoading(false)
    }

    fetchRegisterStatus().then()

    return () => {}
  }, [address])

  return {
    isRegistered: !!address && registered,
    loading
  }
}
