import { ACCOUNT_TIER, TOAST_TYPE } from '@common/constants/common'
import axiosInstance from '@utils/axios'
import { compareAddresses } from '@utils/ethers'
import { showToast } from '@utils/toast'
import { useEffect, useRef, useState } from 'react'

/**
 * Fetch account tier
 * @param {string} account
 * @returns {{ loading: boolean, tier: 'Free' | 'Pro' }}
 */
export const useAccountTier = (account) => {
  const [tier, setTier] = useState(ACCOUNT_TIER.FREE)
  const [loading, setLoading] = useState(true)

  const fetchAccountTier = async (account) => {
    const res = await axiosInstance.get('/proCreators')

    if (res.status !== 200 || !res.data) {
      showToast('Could not fetch the account info.', TOAST_TYPE.ERROR)
      return
    }

    if (
      Array.isArray(res.data.address) &&
      res.data.address
        .map((x) => x.toLowerCase())
        .includes(account.toLowerCase())
    ) {
      setTier(ACCOUNT_TIER.PRO)
    }

    setLoading(false)
  }

  const prevAccount = useRef()

  useEffect(() => {
    if (account && !compareAddresses(prevAccount.current, account)) {
      prevAccount.current = account.toLocaleLowerCase()
      fetchAccountTier(account)
    }
  }, [account])

  return {
    tier: !!account && tier,
    loading
  }
}
