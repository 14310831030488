import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material'
import PaymentSummary from '../PaymentSummary'
import { PaymentLineItemUSD, PaymentLineItemCNY } from '../PaymentLineItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { GOKOO_TOKEN, TOAST_TYPE } from '@common/constants/common'
import { showToast } from '@utils/toast'
import { generateAlipayRequest } from '../Alipay'
import StripePayment from '../StripePayment'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Backdrop from '@components/Backdrop'
import {
  OrderTypes,
  saveOrderInfoLocalStorage,
  supportedPaymentMethod
} from '@utils/payment.helper'
import BigNumber from 'bignumber.js'
import { useWalletContext } from '@features/wallet/WalletContext'
import { toCent } from '@utils/common'
import { useFundsContext } from '../../../..'
import {
  DEPOSIT_DEFAULT_FEE,
  DEPOSIT_FEE
} from '@common/contexts/FundsContext/funds.constant'

const Payment = ({ amount }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const navigate = useNavigate()

  const { goBackUrl, lockView, openFundsDrawer } = useFundsContext()
  const { onConnectMagic, account: address } = useWalletContext()

  const [paymentMethod, setPaymentMethod] = useState(undefined)
  const [paying, setPaying] = useState(false)

  // FIXME: why 7.3?
  const [exchangeRate, setExchangeRate] = useState(7.3)

  const [stripeDrawerOpen, setStripeDrawerOpen] = useState(false)

  const depositAmountInUSD = new BigNumber(amount).multipliedBy(
    GOKOO_TOKEN.rate
  )
  const depositAmountInGOKOO = parseInt(new BigNumber(amount).toNumber())

  const paymentSummary = useMemo(() => {
    // Set the fee percentage and minimum fee
    const feePercentage = new BigNumber(DEPOSIT_FEE) // 2.9%

    const fee = new BigNumber(depositAmountInUSD)
      .multipliedBy(feePercentage)
      .plus(DEPOSIT_DEFAULT_FEE)

    return {
      total: new BigNumber(depositAmountInUSD).plus(fee).toNumber(),
      subTotal: depositAmountInUSD,
      fee
    }
  }, [depositAmountInUSD])

  const depositAmountInCent = toCent(paymentSummary.total)

  const onPaymentMethodChanged = (method) => {
    setPaymentMethod((paymentMethod) => {
      if (paymentMethod === method) {
        return undefined
      }
      return method
    })
  }

  useEffect(() => {
    const callGetExchangeRate = async () => {
      const axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      }
      const res = await axios.get(
        `${process.env.REACT_APP_LICENS_CHECKOUT_ENDPOINT}?type=getExchangerate`,
        axiosConfig
      )
      if (res.data?.rates != null) {
        setExchangeRate(res.data?.rates?.CNY)
      }
    }
    callGetExchangeRate()
  }, [exchangeRate])

  const generateAlipayUrls = useCallback(() => {
    const { redirectUrl } = generateAlipayRequest({
      depositAmount: depositAmountInGOKOO,
      goBackUrl,
      subtotal: paymentSummary.total,
      exchangeRate: exchangeRate,
      wallet: address
    })

    return redirectUrl?.href
  }, [
    address,
    depositAmountInGOKOO,
    exchangeRate,
    goBackUrl,
    paymentSummary.total
  ])

  const onClickPayNow = useCallback(async () => {
    setPaying(true)

    if (!paymentMethod) {
      showToast('Please select a payment method.', TOAST_TYPE.WARNING)
      setPaying(false)
      return
    }

    if (!address) {
      await onConnectMagic()
      setPaying(false)
      return
    }

    if (paymentMethod === 'cny') {
      const alipayUrl = generateAlipayUrls()
      window.location.replace(alipayUrl)
    } else {
      setStripeDrawerOpen(true)
    }
    setTimeout(() => {
      setPaying(false)
    }, 6000)
  }, [address, generateAlipayUrls, onConnectMagic, paymentMethod])

  const handleStripeStatusChange = async (stripeStatus) => {
    if (stripeStatus) {
      saveOrderInfoLocalStorage(stripeStatus.orderId, OrderTypes.deposit, {
        depositAmount: depositAmountInGOKOO,
        paymentMethod: supportedPaymentMethod.stripe,
        goBackUrl
      })

      navigate(`/confirmation/deposit/${stripeStatus.orderId}`)

      setStripeDrawerOpen(false)
      openFundsDrawer(false)
    }
  }

  return (
    <>
      <Box
        sx={{
          width: isMobile ? '100%' : 400,
          maxWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%'
        }}
      >
        {!stripeDrawerOpen && (
          <>
            <Box marginBottom={2}>
              <Typography variant="h3">Select Payment Method</Typography>
              <Stack spacing={2} mt={4}>
                <PaymentLineItemUSD
                  checked={paymentMethod === 'usd'}
                  onLineChecked={() => onPaymentMethodChanged('usd')}
                  amount={depositAmountInCent}
                />
              </Stack>
              <Stack spacing={2} mt={2}>
                <PaymentLineItemCNY
                  checked={paymentMethod === 'cny'}
                  onLineChecked={() => onPaymentMethodChanged('cny')}
                  amount={depositAmountInCent}
                />
              </Stack>
            </Box>

            <Box>
              <PaymentSummary
                subTotal={paymentSummary.subTotal}
                total={paymentSummary.total}
                fee={paymentSummary.fee}
              />

              <Button
                variant="contained"
                color="success"
                size="large"
                onClick={onClickPayNow}
                sx={{
                  lineHeight: 1.4,
                  minWidth: '100%',
                  backgroundColor: 'success.main',
                  ':hover': {
                    backgroundColor: 'success.dark'
                  },
                  cursor: 'pointer'
                }}
              >
                <Box
                  component="span"
                  sx={{
                    marginRight: 1,
                    fontSize: 20
                  }}
                >
                  Pay now
                </Box>
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </Box>
          </>
        )}
        {stripeDrawerOpen && (
          <StripePayment
            onPaymentStart={() => {
              lockView(true)
            }}
            onPaymentEnd={() => {
              lockView(false)
            }}
            onPaymentSucceeded={handleStripeStatusChange}
            amount={depositAmountInCent}
            open={stripeDrawerOpen}
            onClose={() => {
              setStripeDrawerOpen(false)
              openFundsDrawer(false)
            }}
          />
        )}
        {paying && (
          <Backdrop
            open={paying}
            loading={paying}
            message={
              <p style={{ textAlign: 'center' }}>
                Please don&apos;t close or refresh this window during the
                payment process.
              </p>
            }
          />
        )}
      </Box>
    </>
  )
}

export default Payment
