import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef
} from 'react'
// import WalletInfo from './WalletInfo'
import { useRegistered } from '@common/hooks/useRegistered'
import { ACCOUNT_CONTRACT } from '@common/contracts'
import usePersistState from '@common/hooks/usePersistState'
import {
  ACCOUNT_TIER,
  CURRENT_USER_ACCESS_TOKEN
} from '@common/constants/common'
import { Mixpanel } from '@utils/mixpanel'
import { useAccountTier } from '@common/hooks/useAccountTier'
import { useBlacklistCreators } from '@common/hooks/useBlacklistCreators'
import { useAccount, useConnect } from 'wagmi'
import { currentChain, magicConnector } from '@utils/wagmi-config'
import { Magic } from 'magic-sdk'
import { get } from 'lodash'

const SESSION_LENGTH_IN_DAYS = 15

const magic = new Magic(process.env.REACT_APP_API_KEY_CONNECT_MAGIC_LINK, {
  network: {
    rpcUrl: process.env.REACT_APP_PUBLIC_RPC,
    chainId: currentChain.id
  }
})

const WalletContext = createContext({
  onConnectMagic: () => {},
  checkPermissionToAccess: (isCheckProAccount) => {},
  loading: false,
  isRegistered: false,
  isFetchingAccountRegistered: false,
  isLoadingBlacklistCreatorWallets: false,
  blacklistCreatorWallets: []
})

export const WalletProvider = ({ children }) => {
  const { address, isConnecting, isConnected } = useAccount()
  const { connectAsync, isPending } = useConnect()

  const account = (address || '').toLowerCase()

  const [email, setEmail] = usePersistState({
    name: 'CONNECTED_WALLET_EMAIL',
    initialState: ''
  })

  const [accountInfo, setAccountInfo] = usePersistState({
    name: 'ACCOUNT_INFO',
    initialState: {}
  })

  const { isRegistered, loading: isFetchingAccountRegistered } =
    useRegistered(account)

  const { tier, loading: isFetchingAccountTier } = useAccountTier(account)

  const { loading: isLoadingBlacklistCreatorWallets, blacklistCreatorWallets } =
    useBlacklistCreators()

  const triedLoginWithAddress = useRef()

  // handle get magic link token to request our api with authorization
  useEffect(() => {
    if (
      triedLoginWithAddress.current &&
      triedLoginWithAddress.current === address
    ) {
      return
    }

    if (address && isConnected) {
      triedLoginWithAddress.current = address

      magic.user
        .getIdToken({
          lifespan: 60 * 60 * 24 * SESSION_LENGTH_IN_DAYS
        })
        .then((idToken) => {
          localStorage.setItem(CURRENT_USER_ACCESS_TOKEN, idToken)
        })
        .catch()

      magic.user
        .getInfo()
        .then((data) => {
          if (data.email) {
            setEmail(data.email)
          }
        })
        .catch()
    }
  }, [address, isConnected, setEmail])

  const onConnectMagic = useCallback(async () => {
    return await connectAsync({ connector: magicConnector })
  }, [connectAsync])

  useEffect(() => {
    Mixpanel.identify(account)
  }, [account])

  // store account permission into local
  useEffect(() => {
    if (!account) return

    if (isFetchingAccountRegistered || isFetchingAccountTier) return

    if (
      accountInfo?.[account]?.isRegistered === isRegistered &&
      accountInfo?.[account]?.tier === tier
    ) {
      return
    }

    setAccountInfo({
      ...accountInfo,
      [account]: { isRegistered, tier }
    })
  }, [
    account,
    accountInfo,
    isFetchingAccountRegistered,
    isFetchingAccountTier,
    isRegistered,
    tier,
    setAccountInfo
  ])

  const clearData = useCallback(() => {
    setAccountInfo({})
    setEmail(null)
    localStorage.removeItem('@koi/WALLET_TYPE')
    localStorage.removeItem(CURRENT_USER_ACCESS_TOKEN)
    localStorage.removeItem('__TW__/coordinatorStorage/lastConnectedWallet')
  }, [setAccountInfo, setEmail])

  /**
   * Check permission to access a specific route
   * @async
   * @function
   * @param {boolean} isCheckProAccount
   */
  const checkPermissionToAccess = useCallback(
    async (isCheckProAccount) => {
      try {
        let connectedWallet = account
        if (!account) {
          const connector = await onConnectMagic()

          connectedWallet = get(connector, 'accounts[0]')
          if (!connectedWallet) {
            return { isValid: false, error: 'NOT_CONNECT' }
          }
        }

        // check registered
        const isRegister = await ACCOUNT_CONTRACT.isRegistered(connectedWallet)

        if (!isRegister) {
          return { isValid: false, error: 'NOT_REGISTER' }
        }

        // check whitelist
        if (
          isCheckProAccount &&
          !isFetchingAccountTier &&
          tier !== ACCOUNT_TIER.PRO
        ) {
          return { isValid: false, error: 'NOT_PRO_ACCOUNT' }
        }

        return { isValid: true }
      } catch (e) {
        console.log({ e })
      }
      return { isValid: false, error: 'NOT_CONNECT' }
    },
    [account, isFetchingAccountTier, onConnectMagic, tier]
  )

  const openMagicUserModal = useCallback(
    async ({ onDisconnect }) => {
      if (!isConnected) return

      try {
        const isLoggedIn = await magic.user.isLoggedIn()
        if (!isLoggedIn) return

        const walletWidget = magic.wallet.showUI()
        walletWidget.on('disconnect', () => {
          onDisconnect()
        })
      } catch (e) {
        console.log({ e })
      }
    },
    [isConnected, email]
  )

  return (
    <WalletContext.Provider
      value={{
        onConnectMagic,
        checkPermissionToAccess,
        account,
        isConnecting: isConnecting || isPending,
        isRegistered: !!accountInfo?.[account]?.isRegistered,
        isFetchingAccountRegistered,
        accountTier: tier,
        isProAccount: accountInfo?.[account]?.tier === ACCOUNT_TIER.PRO,
        isFetchingAccountTier,
        email,
        isLoadingBlacklistCreatorWallets,
        blacklistCreatorWallets,
        openMagicUserModal,
        clearData
      }}
    >
      <>{children}</>
    </WalletContext.Provider>
  )
}

export const useWalletContext = () => {
  const context = useContext(WalletContext)
  if (!context) {
    throw new Error('useWalletContext must be inside a WalletProvider')
  }
  return context
}
